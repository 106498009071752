<template>
    <div style="height: calc(100% - 50px);width: 100%">
        <NavBreadcrumb></NavBreadcrumb>
        <div style="height: calc(100% - 50px);width: 100%;display: flex;">
            <div v-show="treeShow" class="boxBorder" style="height: 100%;max-height: 720px;width: 25%;flex-direction: row; justify-content: left;overflow-y: auto;">
                <el-tree
                        style="width: 100%"
                        :data="data"
                        :props="defaultProps"
                        node-key="nodeKey"
                        ref="treeNav"
                        :load="getNextLevel"
                        @node-click="nodeClick"
                        :expand-on-click-node="false"
                        lazy
                        :highlight-current="true"
                        :render-content="renderContent">
                </el-tree>
            </div>
            <div class="boxBorder" style="width: 100%;overflow-y: auto;max-height: 720px;height: 100%;">
                <div style="position: absolute;margin-top: 18%">
                    <i :class="directClass" style="font-size: 20px;cursor: pointer;margin-left: -17px" @click="toggleTree"></i>
                </div>
                <ModbusPoint v-if="type === 2" :product-model-id="id" :title="title" @refreshTree="refreshTree"></ModbusPoint>
                <ModbusRegister v-if="type === 3" :point-info-id="id" :title="title" @refreshTree="refreshTree"></ModbusRegister>
                <ModbusCommandDetail v-if="type === 4" :command-id="id" :title="title" @refreshParentTree="refreshParentTree" @setParentNode="setParentNode"></ModbusCommandDetail>
            </div>
        </div>
    </div>
</template>

<script>
import NavBreadcrumb from '../../components/NavBreadcrumb.vue';
import ModbusPoint from "../../views/modbus/modbus-point";
import ModbusRegister from "../../views/modbus/modbus-register";
import ModbusCommandDetail from "../../views/modbus/modbus-command-detail";
import { queryTreeList } from "../../api/modbus/modbusTree";
import message from "@/utils/message";

export default {

    name: "modbus-setting",
    components: {NavBreadcrumb, ModbusPoint, ModbusRegister, ModbusCommandDetail},
    data() {
        return {

            id: '',
            data: [],
            title: '',
            expandedKeys: [],
            treeShow: true,
            directClass: "el-icon-arrow-left",
            defaultProps: {

                children: 'children',
                label: 'name',
                isLeaf: 'isLeaf'
            },
            type: 1,
            testButtonData: 10,
        };
    },
    methods: {

        nodeClick(data) {

            this.type = data.type;
            this.id = data.id;
            this.title = data.name;
        },
        refreshTree() {

            if (this.$refs.treeNav.getCurrentKey()) {

                let node = this.$refs.treeNav.getNode(this.$refs.treeNav.getCurrentKey());
                if (node) {

                    node.loaded = false;
                    node.expand();
                }
            }
        },
        refreshParentTree() {

            if (this.$refs.treeNav.getCurrentKey()) {

                let node = this.$refs.treeNav.getNode(this.$refs.treeNav.getCurrentKey());
                node = node.parent;
                if (node) {

                    node.loaded = false;
                    node.expand();
                }
            }
        },
        setParentNode() {

            this.type = 0;
        },
        getNextLevel(node, resolve) {

            let type = node.level + 1;
            let param = {

                id: node.data.id,
                type: type,
            }
            queryTreeList(param).then((res) => {

                if (res.code === 200) {

                    res.data.map(item => {

                        item.subType = item.type;
                        item.type = type;
                        item.nodeKey = type + '-' + item.id;
                    })
                    if (node.level < 3) {

                        resolve(res.data);
                    } else if (node.level === 3) {

                        res.data.map(item => {

                            item.isLeaf = true;
                        })
                        resolve(res.data);
                    } else {

                        resolve([]);
                    }
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                console.error(err)
                resolve([]);
            });
        },// 树形结构内容渲染
        renderContent: function (h, {node, data, store}) {

            let icon = require('../../assets/icon/cr.png');
            if (data.type === 1) {

                // 产品线
                icon = require('../../assets/icon/pl.png');
            } else if (data.type === 2) {

                // 产品型号
                icon = require('../../assets/icon/pm.png');
            } else if (data.type === 3) {

                // 点表
                icon = require('../../assets/icon/points.png');
            } else if (data.type === 4) {

                if (data.subType === 'READ') {

                    // 读指令
                    icon = require('../../assets/icon/cr.png');
                } else if (data.subType === 'WRITE') {

                    // 写指令
                    icon = require('../../assets/icon/cw.png');
                }
            }
            return (<span><img src={icon} width="14" height="14"/><span style="padding-left: 4px;">{ node.label }</span></span>);
        },
        toggleTree() {

            this.treeShow = !this.treeShow;
            if (this.treeShow) {

                this.directClass = "el-icon-arrow-left";
            } else {

                this.directClass = "el-icon-arrow-right";
            }
        }
    }
}
</script>

<style scoped>
    .boxBorder {

        border-radius: 4px;
        border: 1px solid #CCC;
        margin: 5px;
        padding: 15px;
        background-color: #fff;
    }
</style>
